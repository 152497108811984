import React from "react";
import "./Footer.css";

const Footer = props => (
  <div className="Footer">
    <h1>Contact Me:</h1>
    <h5>Let us setup a coffee meeting. If you like tea, I am flexible.</h5>
    <h4> +977 9808770386</h4>
    <h4> +91 9686050932</h4>
    <h4>ranjeet.rk100@gmail.com</h4>
    <div className="Links">
      <a href="https://www.facebook.com/ranjeet.rk10" target="_blank">
        <img src={require("../images/facebook.svg")} />
      </a>
      <a href="hhttps://twitter.com/Ranjeet10" target="_blank">
        <img src={require("../images/twitter.svg")} />
      </a>
      <a
        href="https://www.instagram.com/ranjeet_kumarsah10/?hl=en"
        target="_blank"
      >
        <img src={require("../images/instagram.svg")} />
      </a>
      <a
        href="https://www.linkedin.com/in/ranjeet-sah-0b7a8393/"
        target="_blank"
      >
        <img src={require("../images/linkedin.svg")} />
      </a>
    </div>
  </div>
);
export default Footer;
