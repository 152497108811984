import React from "react";
import "./Quote.css";

const Quote = props => (
  <div className="Quote">
    <img src={require("../images/left-quote.svg")} />
    <div>
      <p>
        If I were given an hour in which to do a problem upon which my life
        depended, I would spend 40 minutes studying it, 15 minutes reviewing it
        and 5 minutes solving it.
      </p>
      <div className="QuoteTeller">
        <b>-Einstein</b>
      </div>
    </div>
    <div className="QuoteMargin">
      <p>Everything Should Be Made as Simple as Possible, But Not Simpler.</p>
      <div className="QuoteTeller">
        <b>-Einstein</b>
      </div>
    </div>
    <div className="QuotesGrid">
      <h3>Explore</h3>
      <h2>Dream</h2>
      <h1>Discover</h1>
    </div>
  </div>
);
export default Quote;
