import React from "react";
import styled from "styled-components";
import Wave from "./wave";

const Section = props => (
  <SectionGroup image={props.image}>
    <WaveTop>
      <Wave />
    </WaveTop>
    <WaveBottom>
      <Wave />
    </WaveBottom>
    <SectionLogo src={props.logo} />
    <SectionTitleGroup>
      <SectionTitle>{props.title}</SectionTitle>
      <SectionText>{props.text}</SectionText>
    </SectionTitleGroup>
  </SectionGroup>
);

const SectionGroup = styled.div`
  background: url(${props => props.image});
  height: 720px;
  background-size: cover;
  display: grid;
  grid-template-rows: 300px auto;
  grid-gap: 20px;
  position: relative;

  @media (max-width: 640px) {
    height: 820px;
  }
`;

const SectionLogo = styled.img`
  align-self: end;
  width: 150px;
  margin: 0 auto;
`;

const SectionTitleGroup = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  margin: 0 40px;
  grid-gap: 20px;
  grid-template-rows: auto 100%;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

const SectionTitle = styled.h5`
  color: white;
  font-size: 50px;
  margin: 30px 20px 20px 20px;
  line-height: 1.2;

  @media (max-width: 720px) {
    font-size: 40px;
  }
`;
const SectionText = styled.h5`
  color: white;
  margin: 0px;
  font-size: 36px;
`;

const WaveBottom = styled.div`
  position: absolute;
  width: 100%;
  bottom: -6px;
`;

const WaveTop = styled.div`
  position: absolute;
  width: 100%;
  top: -6px;
  transform: rotate(180deg);
`;

export default Section;
