import { Link } from "gatsby";
import React from "react";
import "./Header1.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasScrolled: false,
      isHomeActive: true,
      isPortfolioActive: false,
      isAboutActive: false,
      isContactActive: false
    };
    this.isHomeActive = true;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  homeActive() {
    this.isHomeActive = true;
    this.isPortfolioActive = false;
    this.isAboutActive = false;
    this.isContactActive = false;
  }

  portfolioActive() {
    this.isHomeActive = false;
    this.isPortfolioActive = true;
    this.isAboutActive = false;
    this.isContactActive = false;
  }

  aboutActive() {
    this.isHomeActive = false;
    this.isPortfolioActive = false;
    this.isAboutActive = true;
    this.isContactActive = false;
  }

  contactActive() {
    this.isHomeActive = false;
    this.isPortfolioActive = false;
    this.isAboutActive = false;
    this.isContactActive = true;
  }

  addActive = (ele, from) => {
    switch (from) {
      case "home":
        this.homeActive();
        break;
      case "portfolio":
        this.portfolioActive();
        break;
      case "about":
        this.aboutActive();
        break;
      case "contact":
        this.contactActive();
        break;
      default:
        this.homeActive();
        break;
    }
  };

  handleScroll = event => {
    const scrollTop = window.pageYOffset;
    const homeScroll = 50;
    const portfolioScroll =
      document.getElementById("portfolio").offsetTop - 200;
    const aboutScroll = document.getElementById("about").offsetTop - 200;
    const contactScroll =
      document.getElementById("contact").offsetTop -
      document.getElementById("contact").scrollHeight -
      200;
    if (scrollTop <= homeScroll) {
      this.homeActive();
    } else if (scrollTop >= portfolioScroll && scrollTop <= aboutScroll) {
      this.portfolioActive();
    } else if (scrollTop >= aboutScroll && scrollTop <= contactScroll) {
      this.aboutActive();
    } else if (scrollTop > contactScroll) {
      this.contactActive();
    }
    if (scrollTop > 50) {
      this.setState({ hasScrolled: true });
    } else {
      this.setState({ hasScrolled: false });
    }
  };

  render() {
    return (
      <div
        className={this.state.hasScrolled ? "Header HeaderScrolled" : "Header"}
      >
        <div className="HeaderGroup">
          <div className="HeaderImage">
            <Link to="/">
              <img src={require("../images/logo.png")} width="100" />
            </Link>
          </div>
          <div className="HeaderMenu">
            <Link
              to=""
              className={this.isHomeActive ? "Active" : ""}
              onClick={() => this.addActive(this, "home")}
            >
              Home
            </Link>
            <Link
              to="#portfolio"
              className={this.isPortfolioActive ? "Active" : ""}
              onClick={() => this.addActive(this, "portfolio")}
            >
              Portfolio
            </Link>
            <Link
              to="#about"
              className={this.isAboutActive ? "Active" : ""}
              onClick={() => this.addActive(this, "about")}
            >
              About Me
            </Link>
            <Link
              to="#contact"
              className={this.isContactActive ? "Active" : ""}
              onClick={() => this.addActive(this, "contact")}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
