import React from "react";

import Layout from "../components/layout";
import Wave from "../components/wave";
import SEO from "../components/seo";
import Card from "../components/card";
import Section from "../components/section";
import Quote from "../components/quote";
import Footer from "../components/footer";

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[`ranjeet`, `sah`, `website`]} />
        <div className="Hero">
          <div className="HeroGroup">
            <h1>
              I'M
              <br />
              RANJEET KUMAR SAH
            </h1>
            <p>Software Engineer</p>
            {/* <Link to="/page-2/">Watch the video</Link> */}
            <div className="Logos">
              {/* <img src={require("../images/logo-sketch.png")} width="50" />
              <img src={require("../images/logo-figma.png")} width="50" />
              <img src={require("../images/logo-studio.png")} width="50" />
              <img src={require("../images/logo-framer.png")} width="50" />
              <img src={require("../images/logo-react.png")} width="50" />
              <img src={require("../images/logo-swift.png")} width="50" /> */}
            </div>
            <Wave />
          </div>
          <div className="Cards" id="portfolio">
            <h2>Portfolio</h2>
            <div className="CardGroup">
              <Card
                title="Software Developer"
                text=""
                image={require("../images/code.jpeg")}
              />
              <Card
                title="Business Consultant"
                text=""
                image={require("../images/business-consult.jpeg")}
              />
              <Card
                title="Digital Marketing Consultant"
                image={require("../images/marketing.jpeg")}
              />
              <Card
                title="Software Consultant"
                image={require("../images/consult.jpeg")}
              />
              <Card
                title="Software Instructor"
                image={require("../images/instructor.jpeg")}
              />
              <Card
                title="Software Analyst"
                image={require("../images/software-analysis.jpeg")}
              />
              <Card
                title="System Analyst"
                image={require("../images/system-analysis.jpeg")}
              />
              <Card
                title="Project Manager"
                image={require("../images/manager.jpeg")}
              />
            </div>
          </div>
          <div className="Quote">
            <Quote />
          </div>
          <div id="about">
            <Section
              image={require("../images/background-about.jpeg")}
              logo={require("../images/logo.png")}
              title="About Me"
              text="Result-driven, diligent IT professional with Core Concepts utilizing technical acumen and logical thinking to provide the company with the best software solutions."
            />
          </div>
          <div className="Footer" id="contact">
            <Footer />
          </div>
        </div>
      </Layout>
    );
  }
}
export default IndexPage;
